<template>
<div class="t-key-value">
  <div class="key" :style="{width:kw}">{{label}}</div>
  <div class="space" :style="{width:typeof space === 'number'?space+'rem':space}"></div>
  <div class="value"><slot></slot></div>
</div>
</template>

<script>
export default {
  name: "KeyValue",
  props:{
    label:String,
    keyWidth:{
      type:[Number,String],
      default:"auto"
    },
    space:{
      type:[Number,String],
      default:24
    }
  },
  computed:{
    kw(){
      if (typeof this.keyWidth === 'number')return this.keyWidth+'rem';
      else return this.keyWidth;
    }
  }
}
</script>

<style lang="scss" scoped>
.t-key-value{
  display: flex;
  padding: 10rem 0;
  font-size: 16rem;
  .key{
    color:#666666;
  }
  .value{
    color: #3C3C3C;
  }
}
</style>
