<template>
  <div>
    <div>
      <!--      <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleUpload">批量导入</el-button>-->
      <Dialog :visible.sync="visibleBaseInfo" title="批量编辑" size="small" submitText="确定" :loading="dialogLoading" :before-submit="uploadEdit">
        <p class="hint">此功能仅可编辑学生分班基础信息，请查看示例</p>
        <LineWrapper labelWidth="100px" :cssStyle="{ fontSize:'14px' }">
          <LineItem label="下载示例">
            <el-button size="small" icon="el-icon-download" @click="handleDownFile(0x00)">下载示例</el-button>
          </LineItem>
          <LineItem label="下载模板">
            <el-button size="small" icon="el-icon-download" @click="handleDownFile(0x01)">下载模板</el-button>
          </LineItem>
          <LineItem label="上传文件">
            <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleSelectFile('editFile')">上传文件</el-button>
          </LineItem>
          <p v-if="editFile!=null" style="color:#999;">{{editFile.name}}</p>
        </LineWrapper>
      </Dialog>
      <Dialog :visible.sync="visibleState" title="批量编辑学生状态" size="small" submitText="确定" :loading="dialogLoading" :before-submit="uploadEdit">
        <p class="hint">此功能仅可编辑学生状态基础信息，请查看示例</p>
        <LineWrapper labelWidth="100px" :cssStyle="{ fontSize:'14px' }">
          <LineItem label="下载示例">
            <el-button size="small" icon="el-icon-download" @click="handleDownFile(0x10)">下载示例</el-button>
          </LineItem>
          <LineItem label="下载模板">
            <el-button size="small" icon="el-icon-download" @click="handleDownFile(0x11)">下载模板</el-button>
          </LineItem>
          <LineItem label="上传文件">
            <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleSelectFile('stateFile')">上传文件</el-button>
          </LineItem>
          <p v-if="stateFile!=null" style="color:#999;">{{stateFile.name}}</p>
        </LineWrapper>
      </Dialog>
      <!--      编辑     -->
      <Dialog :visible.sync="visibleEdit" width="828rem" title="编辑" submitText="提交" :before-submit="editSubmit" @close="handleClear" :loading="dialogLoading">
        <el-row style="margin-bottom: 40rem;">
          <el-col :span="8">
            <key-value label="学生姓名">{{ modifyData.details.student_name }}</key-value>
          </el-col>
          <el-col :span="8">
            <key-value label="学生状态">{{ modifyData.details.student_enrol_name }}</key-value>
          </el-col>
          <el-col :span="8">
            <key-value label="就读状态">{{ modifyData.details.enrollment_status_name }}</key-value>
          </el-col>
        </el-row>
        <el-form :model="editFD" ref="editForm" label-position="left" class="form" label-width="100rem" :rules="editRule">
          <div class="form-item">
            <el-form-item label="就读校区" prop="school_id">
              <el-select style="width:100% !important;" v-model="editFD.school_id" v-if="isShow('school_id')" @change="getBuilding">
                <el-option v-for="item in options.school" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <span class="show" v-else>{{ modifyData.details.school_name }}</span>
            </el-form-item>
            <el-form-item label="就读年级" prop="grade_id">
              <el-select style="width:100% !important;" v-model="editFD.grade_id" v-if="isShow('grade_id')">
                <el-option v-for="item in options.grade" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <span class="show" v-else>{{ modifyData.details.grade_name }}</span>
            </el-form-item>
            <el-form-item label="行政班" prop="class_id">
              <el-select
                  v-model="editFD.class_id"
                  placeholder="请搜索"
                  v-if="isShow('class_id')"
                  filterable
                  remote
                  :loading="selectLoading"
                  :remote-method="searchClass"
              >
                <el-option v-for="item in options.clazz" :label="item.class_name" :value="item.id" :key="item.id+'class_name'" />
              </el-select>
              <span class="show" v-else>{{ modifyData.details.class_name }}</span>
            </el-form-item>
            <el-form-item label="学号" prop="student_no">
              <el-input v-model="editFD.student_no" placeholder="请输入" v-if="isShow('student_no')"></el-input>
              <span class="show" v-else>{{ modifyData.details.student_no }}</span>
            </el-form-item>
          </div>
          <el-form-item label="寝室" prop="dormitory">
            <div class="qs">
              <el-select v-if="isShow('building_id')" style="width:100% !important;" placeholder="请选择宿舍楼" v-model="editFD.building_id" @change="onBuildingChange" :loading="selectLoading">
                <el-option v-for="item in options.building" :label="item.dimicile_buliding_name" :value="item.id" :key="item.id+'building'"></el-option>
              </el-select>
              <span class="show" v-else>{{modifyData.details.building_name}}</span>
              <el-select v-if="isShow('floor')" style="width:100% !important;" placeholder="请选择楼层" v-model="editFD.floor" :disabled="!editFD.building_id" @change="onFloorChange" :loading="selectLoading">
                <el-option label="请选择楼层" value="" v-for="item in options.floor " :label="item.label" :value="item.value" :key="item.value+'floor'"></el-option>
              </el-select>
              <span class="show" v-else>{{modifyData.details.floor}}层</span>
              <el-select v-if="isShow('dormitory_id')" style="width:100% !important;" placeholder="请选择房间" v-model="editFD.dormitory_id" :disabled="!editFD.floor" @change="onRoomChange" :loading="selectLoading">
                <el-option v-for="item in options.room" :label="item.name" :value="item.id" :key="item.id+'room'"></el-option>
              </el-select>
              <span class="show" v-else>{{modifyData.details.dormitory_name}}</span>
              <el-select v-if="isShow('live_no')" style="width:100% !important;" placeholder="请选择床位" v-model="editFD.live_no" @change="$forceUpdate()" :disabled="!editFD.dormitory_id" :loading="selectLoading">
                <el-option v-for="item in options.bed" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <span class="show" v-else>{{modifyData.details.live_no}}号</span>
            </div>
          </el-form-item>
        </el-form>
      </Dialog>
      <Dialog :visible.sync="visibleEditState" width="828rem" title="修改状态" submit-text="提交" :before-submit="stateSubmit" @close="handleClear" :loading="dialogLoading">
        <el-form label-position="left" :model="stateFD" class="form" label-width="100rem" ref="stateForm" :rules="stateRule">
          <el-row>
            <el-col :span="8">
              <key-value label="学生姓名">{{ modifyData.details.student_name }}</key-value>
            </el-col>
            <el-col :span="8">
              <key-value label="就读校区">{{ modifyData.details.school_name }}</key-value>
            </el-col>
            <el-col :span="8">
              <key-value label="就读年级">{{ modifyData.details.grade_name }}</key-value>
            </el-col>
          </el-row>
          <div class="hint">注意：调整就读状态和学生状态需有业务部门相关凭证（流程，单据，聊天截图等）</div>
          <div class="form-item">
            <el-form-item label="学生状态" prop="student_enrol">
              <el-select style="width:100% !important;" v-model="stateFD.student_enrol" v-if="isShow('student_enrol')" @change="onStudentStateChange">
                <el-option v-for="item in modifyData.student_enrol" :label="item.value" :value="item.id" :key="item.id+'student_state'"></el-option>
              </el-select>
              <span class="show" v-else>{{ modifyData.details.student_enrol_name }}</span>
            </el-form-item>
            <el-form-item label="就读状态" prop="enrollment_status">
              <el-select style="width:100% !important;" v-model="stateFD.enrollment_status" v-if="isShow('enrollment_status')">
                <el-option v-for="item in options.enrollment_status" :label="item.value" :value="item.id" :key="item.id+'enrollment_status'"></el-option>
              </el-select>
              <span class="show" v-else>{{ modifyData.details.enrollment_status_name }}</span>
            </el-form-item>
          </div>
          <el-form-item label-width="0">
            <div style="color:#3c3c3c;font-size: 16rem;">备注(非必填)</div>
            <el-input type="textarea" resize="none" maxlength="300" show-word-limit v-model="stateFD.notes" v-if="isShow('notes')" placeholder="请输入" :rows="4"></el-input>
            <span class="show" v-else>{{ modifyData.details.notes }}</span>
          </el-form-item>
          <el-form-item label-width="0">
            <div style="color:#3c3c3c;font-size: 16rem;">凭证附件（非必填）</div>
            <div class="annex-list">
              <div class="annex-item" v-for="(item,index) in stateAnnex" :key="'annex-file'+index">{{item.name}} <i class="el-icon-close" @click="stateAnnex.splice(index,1)"></i></div>
            </div>
            <el-button @click="uploadAnnex" :disabled="!!stateAnnex.length">点击上传</el-button>
          </el-form-item>

        </el-form>
      </Dialog>
      <history-dialog :history-dialog-show="visibleHistory" :history-list="historyList" @close="visibleHistory=false">

      </history-dialog>
    </div>
    <list-template
        :search-config="searchConfig"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :loading="loading"
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleClick"
        @onChange="onChange"
        @onBatchImport='handleUpload'
        @onBatchStateImport="changeState"
        @onReset="onReset"
    >
    </list-template>
  </div>
</template>
<script>
import Dialog from "@/components/common/Dialog";
import LineItem from "@/components/common/LineItem";
import LineWrapper from "@/components/common/LineWrapper";
import KeyValue from "@/components/KeyValue";
import HistoryDialog from "@/components/HistoryDialog";
import {tableListMixin} from '@/utils/mixins'
import {handleChooseFile, hideIdCard} from "@/utils";
import {fileDownload, get_class, get_grade, getApi2, postApi2} from "@/api";

export default {
  _config:{route:{path:"list", meta:{title:"列表",keepAlive:true}}},
  components: { Dialog, LineItem, LineWrapper,KeyValue,HistoryDialog },
  mixins: [tableListMixin],
  created() {
    this.getSearchConfig()
    this.getData();
  },
  computed:{
    isShow(){
      return (word)=>this.modifyData.words.includes(word);
    },
  },
  methods: {
    onReset(){
      this.$utils.updateOptions.call(this,'prop','enrollment_status',[]);
    },
    // 点击编辑或编辑状态 ， 获取可修改数据
    getModifyData(id,isState=false){
      this.dialogLoading = true;
      return this.$_axios2.get("/api/school-base/student-division/edit-data?id="+id,{logic:1})
          .then(res=>{
            let data = res.data;
            if (isState){
              this.modifyData.words = data.state;
            }else{
              this.modifyData.words = data.base;
            }
            this.modifyData.details = data.details;
            this.modifyData.student_enrol = data.state_check.student_enrol;
            return data.details;
          })
          .catch(err=>this.dialogLoading = false);
    },
    getSearchConfig() {
      const data = sessionStorage.getItem('USER_INFO')
      if(data) {
        const { school_arr } = JSON.parse(data)
        const list = []
        Object.keys(school_arr).forEach(key => {
          list.push({ value: key.toNumber(), label: school_arr[key] })
        })
        this.$utils.updateOptions.call(this,"prop","school_id",list);
        this.options.school = list;
      }
      get_grade().then((res) => {
        let list = res.map(item => ({ label: item.grade_name, value: item.id }));
        this.options.grade = list;
        this.$utils.updateOptions.call(this,"prop","grade_id",list);
      })
      this.$_axios2.get("api/school-base/student-division/screen",{logic:1}).then(res=>{
        let {student_enrol} = res.data;
        this.$utils.updateOptions.call(this,'prop','student_enrol',student_enrol.map(item=>{
          return {
            label:item.value,
            value:item.id,
            enrollment_status:item.enrollment_status||[]
          }
        }))
      })
    },
    // 搜索行政班
    searchClass(val){
      this.selectLoading = true;
      this.$_axios2.get("api/common/screen/admin-class",{
        params:{
          school_id:this.editFD.school_id,
          grade_id:this.editFD.grade_id,
          class_name:val
        },
        logic:1
      }).then(res=>{
        this.options.clazz = res.data;
      }).finally(res=>this.selectLoading = false);
    },
    // 获取宿舍楼
    getBuilding(school_id,init){
      if (!init){
        this.editFD.building_id = null;
        this.editFD.floor = null;
        this.editFD.dormitory_id = null;
        this.editFD.live_no = null;
      }

      this.selectLoading = true;
      return this.$_axios2.get("api/school-base/student-division/building?school_id="+school_id,{logic:1}).then(res=>{
        this.options.building = res.data;
      }).finally(()=>this.$nextTick(()=>this.selectLoading = false))
    },
    // 当宿舍楼被更改
    onBuildingChange(e,init){
      let option = this.options.building.filter(item=>item.id === e)[0];
      this.selectLoading = true;
      let list = [];
      for (let i = option.floor_min;i<=option.floor_max;i++){
        list.push({
          label:i+" 层",
          value:i
        })
      }
      this.$nextTick(()=>this.selectLoading = false);
      this.options.floor = list;
      if(!init){
        this.editFD.floor = null;
        this.editFD.dormitory_id = null;
        this.editFD.live_no = null;
      }

    },
    // 当楼层被更改
    onFloorChange(e,init){
      this.selectLoading = true;
      if(!init){
        this.editFD.dormitory_id = null;
        this.editFD.live_no = null;
      }
      return this.$_axios2.get("api/school-base/student-division/dormitory",{
        logic:1,
        params:{
          building_id:this.editFD.building_id,
          floor:e
        }
      }).then(res=>{
        this.options.room = res.data;
      }).finally(()=>this.$nextTick(()=>this.selectLoading = false))

    },
    // 当寝室号被更改
    onRoomChange(e,init){
      let option = this.options.room.filter(item=>item.id === e)[0];
      let list = [];
      this.selectLoading = true;
      for (let i = 1;i<=option.num;i++){
        list.push({
          label:i+" 号",
          value:i
        })
      }
      this.options.bed = list;
      if (!init)
      this.editFD.live_no = null;
      this.$nextTick(()=>this.selectLoading = false);
    },
    // 当修改状态 , 学生状态被更改
    onStudentStateChange(e){
      let option = this.modifyData.student_enrol.filter(item=>item.id===e)[0];
      this.options.enrollment_status = option.enrollment_status;
      this.stateFD.enrollment_status = null;
    },
    getData() {
      this.getDataApi('/api2/api/school-base/student-division/list')
    },
    async handleClick(row,button) {
      const { student_enrol, school_id, grade_id, id } = row;

      let loading = this.$loading({text:"请稍后"});
      this.firstValidate = true;
      if (button.type === 'edit'){
        let details = await this.getModifyData(id);
        this.editFD = {
          id,
          school_id: details.school_id,
          grade_id: details.grade_id,
          class_id: details.class_id || "",
          student_no: details.student_no,
          building_id:details.building_id||null,
          floor: details.floor||null,
          dormitory_id: details.dormitory_id||null,
          live_no: details.live_no||null,
        }
        console.log(this.editFD,details.building_id,details.building_id||null);
        if (this.editFD.school_id)
          await this.getBuilding(details.school_id,1);
        if(this.editFD.building_id)
          this.onBuildingChange(this.editFD.building_id,1);
        if (this.editFD.floor)
          await this.onFloorChange(this.editFD.floor,1)
        if (this.editFD.dormitory_id)
          this.onRoomChange(this.editFD.dormitory_id,1)
        this.visibleEdit = true;
        this.dialogLoading = false;
        this.options.clazz = details.class_id?[
          {
            class_name: details.class_name,
            id:details.class_id
          }
        ]:[];
        this.$forceUpdate();
        loading.close();
      }else if(button.type === 'editState'){
        let details = await this.getModifyData(id,true);
        this.dialogLoading = false;
        let enrol = details.student_enrol || null;
        let status = details.enrollment_status || null;
        if (enrol){
          // 筛选学生状态选项
          let filter = this.modifyData.student_enrol.filter(item=>item.id===enrol);
          if (filter.length){
            this.stateFD.student_enrol = enrol;
            let options = filter[0].enrollment_status;
            this.options.enrollment_status = options;
            // 判断当前值是否在选项中 , 如果不在
            if (options.some(item=>item.id===status)){
              this.stateFD.enrollment_status = status;
            }
          }

        }
        this.stateFD.id = id;
        this.$forceUpdate();
        this.visibleEditState = true;
        loading.close();
      }else{
        this.visibleHistory = true;
        this.dialogLoading = true;
        await this.$_axios2.get("/api/school-base/student-division/details?id="+id,{logic:1}).then(res=>{
          this.historyList = res.data;
        }).finally(()=>this.dialogLoading = false)
        loading.close();

      }
    },
    handleClear() {
      this.editFD= {
        school_id: null,
        grade_id: null,
        class_id: null,
        student_no: null,
        building_id: null,
        floor: null,
        dormitory_id: null,
        live_no: null,
      };
      this.stateFD  = {
        student_enrol: null,
        enrollment_status: null,
        notes: null,
        annex:[], // 附件
      }
      this.modifyData = {
        words:[],
        student_enrol:[],
        details:{}
      }
      this.options = Object.assign(this.options,{
        building:[] , //宿舍楼
        floor:[], //楼层
        room:[], //寝室
        bed:[],// 床位
        enrollment_status:[], // 就读状态
      });
      console.log(this.options);
      this.stateAnnex = [];
      this.editFile = null // 批量编辑上传文件
      this.stateFile = null // 批量修改状态上传文件
    },
    editSubmit(done,instance) {
      this.$refs.editForm.validate((valid) => {
        this.firstValidate = false;
        if (valid){
          instance.confirmButtonLoading = true;
          this.$_axios2.post("api/school-base/student-division/edit",this.editFD,{logic:1}).then(res=>{
            this.getData();
            done();
          }).finally(()=> instance.confirmButtonLoading = false)
        }
      });
    },
    stateSubmit(done,instance){
      this.$refs.stateForm.validate(async (valid) => {
        if (valid){
          instance.confirmButtonLoading = true;
          let annex = [];
          for (let item of this.stateAnnex){
            await this.$uploadToOSS_1(item,3,1).then(res=>{
              annex.push(res);
            })
          }
          this.stateFD.annex = annex.join(",");
          this.$_axios2.post("api/school-base/student-division/edit-state",this.stateFD,{logic:1}).then(res=>{
            this.getData();
            done();
          }).finally(()=>instance.confirmButtonLoading = false)
        }
      })
    },
    handleUpload() {
      this.visibleBaseInfo = true
    },
    // 批量修改状态
    changeState(){
      this.visibleState = true;
    },
    // 批量编辑
    uploadEdit(done,instance){
      let fd = new FormData();
      let url = "api/school-base/student-division/import";
      // 批量修改状态
      if (this.visibleState){
        if (this.stateFile === null)return this.$message.warning("请选择文件");
        url = "api/school-base/student-division/import-state";
        fd.append("file",this.stateFile);
      }else {
        if (this.editFile === null)return this.$message.warning("请选择文件");
        fd.append("file", this.editFile);
      }
      instance.confirmButtonLoading = true;
      this.$_axios2.post(url,fd,{logic:1}).then(res=>{
        done();
        this.$message.success(res.msg);
      }).finally(()=>instance.confirmButtonLoading = false)
    },
    /**
     * 下载文件
     * @param type 低4位为 0 ： 下载示例  低四位为1： 下载模板  高四位为0 批量编辑基础信息， 高四位为1： 批量编辑状态
     */
    handleDownFile(type) {
      let fileName = "";
      let name = "";
      switch (type){
        case 0:
          fileName = 'xinshengfenban-base-info-exam.xlsx'
            name = "新生分班基础信息实例"
              break;
        case 1:
          fileName = 'xinshengfenban-base-info-template.xlsx';
          name = "新生分班基础信息模板"
          break;
        case 0x10:
          fileName = 'xinshengfenban-student-state-exam.xlsx';
          name = "新生分班学生状态示例";
          break;
        case 0x11:
          fileName = 'xinshengfenban-student-state-template.xlsx';
          name = "新生分班学生状态模板"
          break;
      }
      fileDownload(`${process.env.VUE_APP_URL_OSS}preview/`+fileName, {}, { name: name, type: 'xlsx' }).then(() => {
        this.visibleBaseInfo = false
      })
    },
    handleSelectFile(word='editFile') {
      this.$tools.chooseFile({accept:'.xls,.xlsx,.xlsm,.csv'},res=>{
        this[word] = res[0];
      })
    },
    uploadAnnex(){
      if(this.stateAnnex.length)return;
      this.$tools.chooseFile(undefined, (files) => {
        this.stateAnnex.push(files[0]);
        console.log(files[0]);
      })
    },
  },
  data() {
    const validateNo = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('学号不能为空'))
      } else if(!/^[1-9]\d*$/.test(value)) {
        callback(new Error('请输入正确的学号'))
      } else if(value.length !== 12) {
        callback(new Error('请输入12位数的学号'))
      } else {
        callback()
      }
    }
    // 寝室验证
    /*const validateDormitory = (rule,value,callback)=>{
      let data = this.editFD;
      if(this.firstValidate)callback();
      else if (!data.building_id)callback(new Error("请选择宿舍楼"));
      else if(!data.floor)callback(new Error("请选择楼层"));
      else if(!data.dormitory_id)callback(new Error("请选择房间"));
      else if(!data.live_no)callback(new Error("请选择床位"));
      else callback()
    }*/
    let $this = this;
    return {
      visibleBaseInfo: false,
      visibleState: false,
      visibleEdit: false,
      visibleEditState:false,
      visibleHistory:false,
      dialogLoading: false,
      selectLoading:false,
      submitLoading:false,
      id: '',
      historyList:[],
      showType: false,
      searchConfig: [
        {
          tag: 'select',
          placeholder: '筛选学生状态',
          prop: 'student_enrol',
          options: [],
          change(e,search){
            let item = $this.$utils.getOptions.call($this,'prop','student_enrol');
            let data = item.filter(it=>it.value===e)[0].enrollment_status.map(itm=>({value:itm.id,label:itm.value}))
            $this.$utils.updateOptions.call($this,'prop','enrollment_status',data);
            search.enrollment_status = null;
          }
        },
        {
          tag: 'select',
          placeholder: '筛选就读状态',
          prop: 'enrollment_status',
          options: []
        },
        {
          tag: 'select',
          placeholder: '筛选就读校区',
          prop: 'school_id',
          options: []
        },
        {
          tag: 'select',
          placeholder: '筛选就读年级',
          prop: 'grade_id',
          options: []
        },
        {
          tag: 'input',
          placeholder: '搜索学号',
          prop: 'student_no'
        },
        {
          tag: 'input',
          placeholder: '搜索学生姓名',
          prop: 'student_name'
        },
      ],
      tableConfig: [
        {
          prop: 'student_enrol_name',
          label: '学生状态',
        },
        {
          prop: 'enrollment_status_name',
          label: '就读状态',
        },
        {
          prop: 'student_name',
          label: '学生姓名',
        },
        {
          prop: 'student_no',
          label: '学号',
        },
        {
          prop: 'school_name',
          label: '就读校区',
        },
        {
          prop: 'grade_name',
          label: '就读年级',
        },
        {
          prop: 'class_name',
          label: '行政班',
        },
        {
          prop: 'dormitory_name',
          label: '寝室',
        },
        {
          label: '操作',
          width: 210,
          handle: true,
          fixed: 'right',
        }
      ],
      editFile:null, // 批量编辑文件
      stateFile:null, // 批量修改状态文件
      options: {
        school:[], // 就读校区
        grade:[] , // 就读年级
        clazz:[], //行政班
        building:[] , //宿舍楼
        floor:[], //楼层
        room:[], //寝室
        bed:[],// 床位
        enrollment_status:[], // 就读状态
      },
      // 编辑 edit form data
      editFD: {
        school_id: null,
        grade_id: null,
        class_id: null,
        student_no: null,
        building_id: null,
        floor: null,
        dormitory_id: null,
        live_no: null,
      },
      // 修改状态 state form data
      stateFD:{
        student_enrol: null,
        enrollment_status: null,
        notes: null,
        annex:[], // 附件
      },
      // 修改状态时选择的附件文件
      stateAnnex:[],
      // 可修改数据
      modifyData: {
        words:[],
        student_enrol:[],
        details:{}
      },
      firstValidate:true,
      editRule: {
        school_id: [{ required: true, message:"请选择就读校区", trigger: 'change' }],
        grade_id: [{ required: true, message:"请选择就读年级", trigger: 'change' }],
        class_id: [{ required: true, message: '请选择行政班', trigger: 'change' }],
        student_no: [{ required: true, message: '请输入学号', trigger: 'change' }],
      },
      stateRule:{
        student_enrol:[{ required: true, message: '请选择学生状态', trigger: 'change' }],
        enrollment_status:[{ required: true, message: '请选择就读状态', trigger: 'change' }],
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.hint{
  color:#666;
  margin: 5px 0;
}
.tips {
  margin-top: -20px;
  margin-bottom: 20px;
  font-size: 12px;
  // color: #e6a23c;
  color: #c0c4cc;
  i {
    margin-right: 3px;
    font-size: 14px;
  }
}
.form{
  ::v-deep .el-select, ::v-deep .el-input{
    width:100% !important;
  }
  ::v-deep .el-form-item__label{
    font-size: 16rem;
    color:#333;
  }
}
.form-item{

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40rem;
}
.qs{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10rem;
}
.hint{
  color: #F6AD3C;
  margin-bottom: 20rem;
}
.show{
  color:#3C3C3C;
  font-size: 16rem;
}
.annex-list{
  .annex-item{
    display: flex;
    align-items: center;
    i{
      margin-left: 40rem;
      cursor: pointer;
    }
  }
}
</style>
